export const clearEdgeFillings = () => ({
  type: 'CLEAR_EDGE_FILLINGS'
});

export const removeEdgeFilling = edgeFilling => ({
  type: 'REMOVE_EDGE_FILLING',
  edgeFilling
});

export const addEdgeFilling = edgeFilling => ({
  type: 'ADD_EDGE_FILLING',
  edgeFilling
});

export const addEdgeFillings = edgeFillings => ({
  type: 'ADD_EDGE_FILLINGS',
  edgeFillings
});

export const changeEdgeFilling = edgeFilling => ({
  type: 'CHANGE_EDGE_FILLING',
  edgeFilling
});

export const updateLastPath = (id, path) => ({
  type: 'UPDATE_LAST_PATH',
  edgeFillingId: id,
  path: path,
});

export const addPathToLastEdgeFilling = path => ({
  type: 'ADD_PATH_TO_LAST_EDGE_FILLING',
  path: path,
});

export const removeLastPath = edgeFillingId => ({
  type: 'REMOVE_LAST_PATH',
  edgeFillingId: edgeFillingId,
});
